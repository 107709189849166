import React from "react"
import Layout from "../../components/Layout"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import MemberList from "../../components/MemberList"

export default function VisitingFellows({ pageContext: { language }, data: { fellows, text } }) {

	return(
		<Layout
      lang={language}
			availableLanguages={["th", "en"]}
      title={"Visiting Fellows"}
      pathname={"/visiting-fellows/"}
			customTitle={true}
    >
      <MDXRenderer>{text.nodes[0].body}</MDXRenderer>
      <h2>{language === "th" ? "นักวิจัยอาคันตุกะปัจจุบัน" : "Current Visiting Fellows"}</h2>
      <MemberList data={fellows.nodes.filter(node => node.frontmatter.currentFellow === true)} lang={language} />
      <h2>{language === "th" ? "ผู้เข้าร่วมโครงการในอดีต" : "Past Visiting Fellows"}</h2>
      <MemberList data={fellows.nodes.filter(node => node.frontmatter.currentFellow !== true)} lang={language} />
		</Layout>
	)

}

export const pageQuery = graphql`
  query VisitingFellowsQuery($language: String!) {
    text: allMdx(filter: {
      fields: {
        collection: {eq: "snippets"},
        lang: {eq: $language}
        originalPath: {eq: "/visiting-fellows/"}
      }
    }) {
      nodes {
        body
      }
    }
    fellows: allMdx(
      filter: {
        fields: {
          collection: {eq: "members"},
          lang: {eq: $language}
        },
        frontmatter: {
          firstVisit: {gt: 0}
        }
      },
      sort: {
        fields: [frontmatter___firstVisit, frontmatter___title],
        order: [DESC, ASC]
      }
    ) {
      nodes {
        frontmatter {
          currentFellow
          firstVisit
          memberid
          title
          org
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 200, height: 200)
            }
          }
        }
        fields {
          originalPath
          lang
        }
      }
    }
  }
`